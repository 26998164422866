<template>
  <div class="animated fadeIn">
    <loading v-if="vLoading" />
    <v-dialog width="650" v-model="editDialog">
      <v-card class="cards pa-3">
        <v-card-title class="text-right primary--text mb-3"
          >ویرایش درخواست</v-card-title
        >
        <v-card-text>
          <div class="text-center" v-if="gettingData">
            <v-progress-circular
              :size="50"
              :width="7"
              color="primary"
              indeterminate
              class="mt-4"
            ></v-progress-circular>
          </div>
          <div v-else>
            <v-row>
              <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <v-select
                  v-model="selectedRequest.insuranceId"
                  :items="insurTypes"
                  item-text="text"
                  item-value="value"
                  label="نوع بیمه *"
                  menu-props="auto"
                  hide-details
                  outlined
                  dense
                ></v-select>
                <v-checkbox
                  label="پزشک خارج از درمانگاه"
                  id="isOut"
                  color="primary"
                  v-model="selectedRequest.isOut"
                  name="isOut"
                  class="outPatientCheckbox"
                >
                </v-checkbox>
                <v-autocomplete
                  v-model="selectedRequest.docId"
                  :items="drNames"
                  clearable
                  item-text="text"
                  item-value="id"
                  label="نام پزشک"
                  class="pa-0"
                  outlined
                  dense
                  v-if="!selectedRequest.isOut"
                ></v-autocomplete>
                <v-text-field
                  v-model="selectedRequest.outDoctor"
                  label="پزشک نویسنده نسخه"
                  outlined
                  dense
                  v-else
                ></v-text-field>
                <v-text-field
                  outlined
                  dense
                  v-model="selectedRequest.labTermNumber"
                  label=" شماره پذیرش آزمایش *"
                  :editable="true"
                  class="pa-0"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <vuetify-money
                  v-model="selectedRequest.cost"
                  label="هزینه(﷼) *"
                  :valueWhenIsEmpty="whenIsEmpty"
                  :options="vMoneyOptions"
                  type="number"
                  outlined
                  dense
                  style=""
                />
                <v-textarea
                  dense
                  outlined
                  label="توضیحات *"
                  v-model="description"
                  rows="10"
                ></v-textarea>
              </v-col>
            </v-row>

            <div class="text-right">
              <v-btn
                :loading="changing"
                :disabled="
                  !description ||
                  !selectedRequest.cost ||
                  !selectedRequest.labTermNumber ||
                  !selectedRequest.insuranceId
                "
                class="submit-btn primary-btn px-2 w-50"
                @click="doChange()"
                >ویرایش</v-btn
              >
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog width="650" v-model="cancelDialog">
      <v-card class="cards pa-3">
        <v-card-title class="text-right red--text mb-3"
          >لغو درخواست</v-card-title
        >
        <v-card-text>
          <div
            class="text-right mb-3"
            style="font-size: 18px; line-height: 22px"
          >
            شما در حال لغو آزمایش شماره
            <b>{{ selectedRequest.labTermNumber }}</b> هستید، درصورت اطمینان
            توضیحات لغو را وارد نمایید.
          </div>
          <v-textarea
            class="mt-3"
            dense
            outlined
            v-model="description"
            label="توضیحات"
          ></v-textarea>
          <div class="text-right">
            <v-btn
              :loading="changing"
              :disabled="!description"
              @click="doCancel"
              class="primary-btn"
              >لغو درخواست</v-btn
            >
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-col cols="12">
      <v-card-title
        style="background-color: transparent !important"
        class="ps-0 pe-0"
      >
        <v-row style="padding-left: 10px !important">
          <v-col md="6" cols="12">
            <h3>خدمات آزمایشگاه‌</h3>
          </v-col>
          <v-col v-if="role == 'labReception'" md="6" cols="12">
            <v-btn
              style="float: left"
              class="primary-btn"
              @click="gotoNewRequest()"
            >
              <v-icon left>add</v-icon>
              <h6 class="mt-1">ثبت درخواست جدید</h6>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <mobile-text
        v-if="deviceType == 'mobile' && role != 'labReception'"
        :title="''"
      />
      <v-card class="cards pa-3" v-else>
        <v-card-text>
          <v-radio-group v-model="filterType" row>
            <v-radio
              label="فیلتر با تاریخ درخواست"
              value="filterByDate"
            ></v-radio>
            <v-radio
              label="فیلتر با شماره پذیرش"
              value="filterByLabNumber"
              @click="labTermNumber = ''"
            ></v-radio>
          </v-radio-group>
          <div>
            <div v-if="filterType == 'filterByDate'">
              <v-row v-if="role == 'reception'" class="align-items-center">
                <v-icon class="text-danger mb-5"> priority_high </v-icon>
                <p class="text-danger">
                  بازه زمانی انتخابی نمی‌تواند بیشتر از ۱۳ ساعت باشد
                </p>
              </v-row>
              <v-row class="time-row">
                <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                  <span id="dateFrom">
                    <v-text-field
                      outlined
                      dense
                      type="text"
                      append-icon="calendar_today"
                      v-model="dateFrom"
                      label=" تاریخ از "
                      :editable="true"
                      class="date-input"
                    >
                    </v-text-field>
                  </span>

                  <date-picker
                    v-model="dateFrom"
                    element="dateFrom"
                    color="#00a7b7"
                  />
                </v-col>
                <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                  <span id="dateTo">
                    <v-text-field
                      outlined
                      dense
                      type="text"
                      append-icon="calendar_today"
                      v-model="dateTo"
                      label=" تاریخ تا "
                      :editable="true"
                      class="date-input"
                    >
                    </v-text-field>
                  </span>

                  <date-picker
                    v-model="dateTo"
                    element="dateTo"
                    color="#00a7b7"
                  />
                </v-col> </v-row
              ><v-row class="time-row">
                <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                  <span id="timeFrom">
                    <v-text-field
                      outlined
                      dense
                      append-icon="schedule"
                      v-model="timeFrom"
                      label=" ساعت از "
                      :editable="true"
                      class="date-input"
                    >
                    </v-text-field>
                  </span>

                  <date-picker
                    v-model="timeFrom"
                    element="timeFrom"
                    color="#00a7b7"
                    type="time"
                  />
                </v-col>
                <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                  <span id="timeTo">
                    <v-text-field
                      outlined
                      dense
                      append-icon="schedule"
                      v-model="timeTo"
                      label=" ساعت تا "
                      :editable="true"
                      class="date-input"
                    >
                    </v-text-field>
                  </span>

                  <date-picker
                    v-model="timeTo"
                    element="timeTo"
                    color="#00a7b7"
                    type="time"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-btn
                    class="primary-btn submit-btn"
                    style="float: right"
                    @click="getServicesByDate()"
                    :disabled="Busy"
                    >اعمال</v-btn
                  >
                </v-col>
              </v-row>
            </div>
            <div v-else>
              <v-row>
                <v-col md="6" cols="9">
                  <v-text-field
                    outlined
                    dense
                    type="number"
                    v-model="labTermNumber"
                    label=" شماره آزمایش پذیرش"
                    @keyup.enter="labTermNumber ? getServicesByNumber() : ''"
                  ></v-text-field>
                </v-col>
                <v-col md="6" cols="3">
                  <v-btn
                    class="primary-btn submit-btn"
                    style="float: right"
                    :disabled="!labTermNumber"
                    @click="getServicesByNumber()"
                    :loading="Busy"
                    >اعمال</v-btn
                  >
                </v-col>
              </v-row>
            </div>
            <hr />

            <h6 v-if="filterType == 'filterByDate'" class="resultBox my-2">
              <v-row style="margin: 20px 10px">
                <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                  <div class="common-text common-div">
                    <strong>
                      <v-icon color="primary" class="me-1"
                        >mdi-script-text-outline</v-icon
                      >
                      تعداد درخواست‌ها:
                      {{ Items.length }}
                    </strong>
                  </div>
                </v-col>
                <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                  <div class="common-text common-div">
                    <v-icon color="primary" class="me-1">mdi-cash</v-icon>
                    <strong>
                      مجموع هزینه‌ها:
                      {{ Number(totalCost).toLocaleString() + " ریال" }}
                    </strong>
                  </div>
                </v-col>
                <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                  <div class="common-text common-div">
                    <v-icon color="primary" class="me-1"
                      >mdi-cash-multiple</v-icon
                    >
                    <strong>
                      مجموع پرداخت شده‌ها:
                      {{ Number(totalPaid).toLocaleString() + " ریال" }}
                    </strong>
                  </div>
                </v-col>
              </v-row>
            </h6>
            <div style="float: left" class="me-3 ms-3 mt-3">
              <v-btn
                text
                class="refresh-btn pe-0 ps-0"
                @click="getServices()"
                :loading="Busy"
                ><v-icon> refresh</v-icon></v-btn
              >
            </div>
            <v-text-field
              v-model="Filter"
              label="جستجو"
              single-line
              hide-details
              prepend-inner-icon="mdi-magnify"
              class="text-right mb-2 mt-2 me-0 ms-0 search-input dr-search"
              filled
              rounded
              clearable
            ></v-text-field>
            <b-table
              responsive
              show-empty
              :fields="Fields"
              :items="Items"
              empty-text="در بازه زمانی انتخاب شده درخواستی ثبت نشده‌است"
              empty-filtered-text="در بازه زمانی انتخاب شده درخواستی ثبت نشده‌است"
              :filter="Filter"
              :busy="Busy"
              :current-page="CurrentPage"
              :per-page="PerPage"
              @filtered="onFiltered"
            >
              <template v-slot:head()="data">
                <div style="text-align: center; vertical-align: middle">
                  {{ data.label }}
                </div>
              </template>
              <template v-slot:cell()="data">
                <div style="text-align: center; vertical-align: middle">
                  {{
                    typeof data.value === "number"
                      ? Number(data.value).toLocaleString()
                      : data.value
                  }}
                </div>
              </template>
              <template v-slot:cell(index)="data">
                <div style="text-align: center; vertical-align: middle">
                  {{ data.index + PerPage * (CurrentPage - 1) + 1 }}
                </div>
              </template>
              <template v-slot:cell(operation)="data">
                <!--:to="'/labReception/labRequestId/' + data.item.id"-->
                <div style="text-align: center; vertical-align: middle">
                  <div v-if="!data.item.cancel">
                    <v-btn
                      class="primary-btn pa-2"
                      :to="
                        role == 'admin'
                          ? '/admin/labRequestId/' + data.item.id
                          : '/labReception/labRequestId/' + data.item.id
                      "
                      ><v-icon> visibility</v-icon></v-btn
                    >
                    <div v-if="role == 'labReception'">
                      <v-btn
                        @click="editReception(data.item)"
                        class="primary-btn pa-2 ma-3"
                        ><v-icon> edit</v-icon></v-btn
                      >
                      <v-btn
                        @click="cancelReception(data.item)"
                        color="red"
                        class="text-light mb-2 pa-2 ma-3 red-btn"
                        ><v-icon> close </v-icon></v-btn
                      >
                    </div>
                  </div>
                  <div class="text-center" v-else>لغو شده</div>
                </div>
              </template>
              <div slot="table-busy" class="text-center text-primary my-2">
                <v-progress-circular
                  indeterminate
                  color="primary"
                  class="align-middle"
                ></v-progress-circular>
              </div>
            </b-table>
            <v-pagination
              v-model="CurrentPage"
              :length="Math.ceil(TotalRows / PerPage)"
              :total-visible="5"
              prev-icon="arrow_back"
              next-icon="arrow_forward"
            ></v-pagination>
            <v-select
              label="تعداد در هر صفحه:"
              style="width: 150px"
              v-model="PerPage"
              :items="perPageOptions"
              item-text="text"
              item-value="value"
            ></v-select>
          </div>
        </v-card-text>
      </v-card>
    </v-col>
  </div>
</template>
<script>
const MobileText = () => import("@/components/MobileText");
import moment from "moment-jalaali";
import VuePersianDatetimePicker from "vue-persian-datetime-picker";
import { mapGetters } from "vuex";
export default {
  components: {
    datePicker: VuePersianDatetimePicker,
    MobileText,
  },
  data() {
    return {
      CurrentPage: 1,
      PerPage: 10,
      perPageOptions: [
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
      ],
      TotalRows: "",
      Filter: "",
      selectedRequest: {},
      editDialog: false,
      cancelDialog: false,
      gettingData: false,
      changing: false,
      description: "",
      filterType: "filterByDate",
      Busy: false,
      labTermNumber: "",
      Items: [],
      totalCost: "",
      totalPaid: "",
      role: "",
      dateFrom: moment(new Date()).format("jYYYY/jMM/jDD"),
      dateTo: moment(new Date()).format("jYYYY/jMM/jDD"),
      timeFrom: "00:00",
      timeTo: "23:59",
      currentDate: moment(new Date()).format("jYYYY-jMM-jDD"),
    };
  },

  methods: {
    getServices() {
      this.filterType == "filterByDate"
        ? this.getServicesByDate()
        : this.getServicesByNumber();
    },
    getServicesByDate() {
      //NOTE getting services info by start date and end date when page mount
      this.Busy = true;
      this.$http
        .post(
          this.baseUrl + "/clinic/lab/visits/all",
          {
            startDate: this.dateFrom + this.timeFrom,
            endDate: this.dateTo + this.timeTo,
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          this.Busy = false;
          if (res.status == 200) {
            this.Items = res.data.allVisits;
            this.totalCost = res.data.totalCost;
            this.totalPaid = res.data.totalPaid;
            this.Items.forEach((service) => {
              if (service.cancel) {
                service._rowVariant = "danger";
              }
            });
            this.TotalRows = this.Items.length;
            this.CurrentPage = 1;
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
          }
        })
        .catch((err) => {
          this.Busy = false;
          this.toast("خطا: " + err.response.data, "error");
        });
    },
    getServicesByNumber() {
      //NOTE getting services info by start date and end date when page mount
      this.Busy = true;
      this.$http
        .post(
          this.baseUrl + "/clinic/lab/visits/searchCode",
          {
            code: this.labTermNumber,
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          this.Busy = false;
          if (res.status == 200) {
            this.Items = res.data;
            this.Items.forEach((service) => {
              if (service.cancel) {
                service._rowVariant = "danger";
              }
            });
            this.TotalRows = this.Items.length;
            this.CurrentPage = 1;
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
          }
        })
        .catch((err) => {
          this.Busy = false;
          this.toast("خطا: " + err.response.data, "error");
        });
    },
    gotoNewRequest() {
      this.$router.push("/labReception/newRequest");
    },
    editReception(item) {
      this.selectedRequest = [];
      this.description = "";
      this.editDialog = true;
      this.gettingData = true;
      this.$http
        .post(
          this.baseUrl + "/clinic/lab/visits/id",
          {
            labRequestId: item.id,
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          this.gettingData = false;
          if (res.status == 200) {
            this.selectedRequest = res.data.labStatusData;
            this.selectedRequest.id = item.id;
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
          }
        })
        .catch((err) => {
          this.gettingData = false;
          this.editDialog = false;
          this.toast("خطا: " + err.response.data, "error");
        });
    },
    cancelReception(item) {
      this.selectedRequest = item;
      this.description = "";
      this.cancelDialog = true;
    },
    doCancel() {
      this.changing = true;
      this.$http
        .post(
          this.baseUrl + "/clinic/lab/visits/cancel",
          {
            labRequestId: this.selectedRequest.id,
            description: this.description,
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          this.changing = false;
          if (res.status == 200) {
            this.toast(res.data, "success");
            this.cancelDialog = false;
            this.getServices();
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
          }
        })
        .catch((err) => {
          this.changing = false;
          this.toast("خطا: " + err.response.data, "error");
        });
    },
    doChange() {
      this.changing = true;
      this.$http
        .post(
          this.baseUrl + "/clinic/lab/visits/edit",
          {
            labRequestId: this.selectedRequest.id,
            insuranceId: this.selectedRequest.insuranceId,
            docId:
              this.selectedRequest.isOut || !this.selectedRequest.docId
                ? ""
                : this.selectedRequest.docId,
            isOut: this.selectedRequest.isOut,
            outDoctor: this.selectedRequest.isOut
              ? this.selectedRequest.outDoctor
              : "",
            cost: this.selectedRequest.cost,
            labTermNumber: this.selectedRequest.labTermNumber,
            description: this.description,
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          this.changing = false;
          if (res.status == 200) {
            this.toast(res.data, "success");
            this.editDialog = false;
            this.getServices();
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
          }
        })
        .catch((err) => {
          this.changing = false;
          this.toast("خطا: " + err.response.data, "error");
        });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.TotalRows = filteredItems.length;
      this.CurrentPage = 1;
    },
  },
  computed: {
    ...mapGetters(["drNames", "insurTypes"]),
    Fields() {
      let Fields = [];
      if (this.role == "reception") {
        Fields = [
          { key: "index", label: "#" },
          { key: "patientName", label: " بیمار" },
          { key: "labStatus", label: " وضعیت" },
          { key: "date", label: "تاریخ " },
          { key: "cost", label: "هزینه آزمایش (ریال)" },
          { key: "paidCost", label: "هزینه پرداخت‌ شده (ریال)" },
          { key: "labTermNumber", label: "شماره پذیرش آزمایش " },
          { key: "labPaymentStatus", label: "وضعیت پرداخت" },
          { key: "receptionName", label: "کارمند پذیرش" },
        ];
      } else {
        if (this.deviceType == "mobile") {
          Fields = [
            { key: "patientName", label: " بیمار" },
            { key: "date", label: "تاریخ " },
            { key: "operation", label: "مشاهده" },
          ];
        } else {
          Fields = [
            { key: "index", label: "#" },
            { key: "patientName", label: " بیمار" },
            { key: "labStatus", label: " وضعیت" },
            { key: "date", label: "تاریخ " },
            { key: "cost", label: "هزینه آزمایش (ریال)" },
            { key: "paidCost", label: "هزینه پرداخت‌ شده (ریال)" },
            { key: "labTermNumber", label: "شماره پذیرش آزمایش " },
            { key: "labPaymentStatus", label: "وضعیت پرداخت" },
            { key: "receptionName", label: "کارمند پذیرش" },
            { key: "operation", label: "مشاهده" },
          ];
        }
      }
      return Fields;
    },
  },
  mounted() {
    this.role = localStorage.getItem("role");
    if (this.role == "reception") {
      this.dateFrom = moment(new Date())
        .subtract(13, "hour")
        .format("jYYYY/jMM/jDD");
      this.timeFrom = moment(new Date()).subtract(13, "hour").format("HH:mm");
      this.timeTo = moment(new Date()).format("HH:mm");
    }
    this.getServicesByDate();
  },
};
</script>
